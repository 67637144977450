import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import DatosSiniestro from '../components/DatosSiniestro'
import TabsSiniestro from '../components/TabsSiniestro'
import { SiniestroContext } from '../context/SiniestroContext'
import { GestionContext } from '../context/GestionContext'
import useSiniestrosConnection from '../api/SiniestrosConnection'
import useGestionConnection from '../api/GestionConnection'
import { BottomLeftButton, BottomRightButton } from '../components/utils/Buttons'
import DeleteButtonDialog from '../components/utils/DeleteButtonDialog'
import { useForm, FormProvider } from 'react-hook-form'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import useCheckAbogado from "../api/CheckAbogado"
import useGetUserLegacyId from '../api/GetUserLegacyIdFromToken'


const ALERT_DURATION = 4000 // ms

export default function Siniestro(props) {
  const [siniestro, setSiniestro] = useState({});
  const [gestion, setGestion] = useState({})
  const incomingState = useLocation().state ?? false // true if new siniestro, else siniestro, false si vino con link directo
  // console.log('incomingState', incomingState)
  const history = useHistory()
  const SiniestrosConnection = useSiniestrosConnection()
  const GestionConnection = useGestionConnection()

  const esAbogado = useCheckAbogado()
  const userId = useGetUserLegacyId()

  const [canDelete, setCanDelete] = useState(true)
  const [saved, setSaved] = useState(false)
  const [toast, setToast] = useState(false)

  // si viene de la lista, es el id que viene, si no usa el link
  const urlId = window.location.pathname.split("/")[2]
  let idSiniestro = incomingState === true ? undefined : incomingState?.sro_id ?? urlId
  // console.log('idSiniestro', idSiniestro)

  const formMethods = useForm({ ...siniestro, gestion })

  useEffect(() => {
    const fetchSiniestro = async (id) => {
      try {
        const response = await SiniestrosConnection.get('/' + id)
        setSiniestro(response.data.data)
      } catch (e) {
        console.log('error en fetch un siniestro', e)
      }
    }
    const fetchGestion = async (tipo) => {
      try {
        const result = await GestionConnection.get('/' + tipo + '/' + idSiniestro)
        setGestion(prevGestion => {
          return { ...prevGestion, [tipo]: [...result.data.gestion[tipo]] }
        })
        console.log('gestion', tipo, result)
      } catch (err) {
        console.error('failed to get gestion')
      }
    }

    if (incomingState.success) {
      setSaved(true)
      setToast(true)
    }

    if (incomingState === true) {
      setCanDelete(false)
      formMethods.reset({})
      // SI ES NUEVO POPULAMOS VACIO para que no se rompan los componentes hijos
      setSiniestro({ sas_damnificados: [], sas_adjuntos: [] })
      setGestion({ publica: [], privada: [] })
    } else {
      console.log('No es un nuevo siniestro.', idSiniestro)
      console.log('SINIESTRO INCOMING STATE.', incomingState)
      fetchSiniestro(idSiniestro)
      fetchGestion('publica')
      fetchGestion('privada')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingState])

  useEffect(() => {
    console.log('se cargo este siniestro', { ...siniestro, gestion })
    formMethods.reset({ ...siniestro, gestion })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siniestro, gestion])


  const onSubmit = () => {
    const valores = formMethods.getValues()
    const siniestroActualizado = {
      ...siniestro,
      ...valores,
      sro_usuariocreador: userId
    }
    setSiniestro(siniestroActualizado)
    console.log('SINIESTRO ACTUALIZADO', siniestroActualizado)

    if (!((siniestroActualizado.sro_usuario != null) &&
      siniestroActualizado.sro_companiaaseguradora &&
      siniestroActualizado.sro_fechavencimiento &&
      siniestroActualizado.sro_estadoactual_estado_siniestro
    )) {
      console.log('NO SE PUEDE GUARDAR ASI, ta todo vacio')
      setSaved(false)
      setToast(true)
      return
    }

    try {
      console.log('GESTION onSubmit', gestion)
      if (incomingState === true) {
        console.log('antes del POST siniestro', siniestro)
        console.log('antes del POST valores mas estado', siniestroActualizado)
        SiniestrosConnection.post('/nuevo', siniestroActualizado).then((res) => {
          console.log('es nuevo, hago POST y PUSHEO RUTA', res.data)
          setSaved(true)
          idSiniestro = res.data.data.sro_id
          console.log('idSiniestro post then', idSiniestro)
          // cargar gestiones ahora que tenemos id
          const promises = []
          if (gestion.publica.length > 0) {
            promises.push(GestionConnection.post('/publica/' + idSiniestro, gestion.publica))
          }
          if (gestion.privada.length > 0) {
            promises.push(GestionConnection.post('/privada/' + idSiniestro, gestion.privada))
          }
          return Promise.all(promises)
          // won't be new anymore after pushing the new route, so send SINIESTRO as the incomingState.
        }).then(() => {
          history.replace('/siniestros/' + idSiniestro, { ...siniestroActualizado, success: true })
        }).catch(err => {
          console.log('error posteando nuevo', err)
          setSaved(false)
          setToast(true)
        })
      } else {
        console.log('es viejo, hago PUT.', parseInt(idSiniestro), siniestroActualizado)
        Promise.all([
          SiniestrosConnection.put('/' + parseInt(idSiniestro), siniestroActualizado),
          GestionConnection.put('/publica/' + parseInt(idSiniestro), gestion.publica),
          GestionConnection.put('/privada/' + parseInt(idSiniestro), gestion.privada),
        ]).then(() => setSaved(true))
          .catch(() => setSaved(false))
          .finally(() => setToast(true))
      }
      console.log('saved post gestion?', saved)
    } catch (e) {
      console.log('error al guardar siniestro', e)
    }
  }

  const onDelete = () => {
    console.log('DELETE BUTTON!')
    try {
      SiniestrosConnection.delete('/' + idSiniestro)
    } catch (e) {
      console.log('error al borrar siniestro', e)
    }
    history.replace('/siniestros')
  }

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    setToast(false)
  }


  return (
    <SiniestroContext.Provider value={{ siniestro, setSiniestro }}>
      <GestionContext.Provider value={{ gestion, setGestion }}>
        <div>
          <h1>Siniestro</h1>
          <FormProvider {...formMethods}>
            <form noValidate autoComplete="off" onSubmit={formMethods.handleSubmit(onSubmit)}>
              <DatosSiniestro />

              {(incomingState !== true) && <TabsSiniestro />}
              <BottomLeftButton variant="contained" color="primary" style={{ float: "left" }} onClick={() => history.push("/siniestros")}>Volver</BottomLeftButton>
              {esAbogado &&
                <>
                  <BottomRightButton variant="contained" color="primary" type="submit">Guardar</BottomRightButton>
                  <DeleteButtonDialog style={{ float: 'right' }} delete={onDelete} variant="contained" color="error" canDelete={canDelete}>Eliminar</DeleteButtonDialog>
                </>
              }
            </form>
          </FormProvider>
        </div >
        <Snackbar open={toast} autoHideDuration={ALERT_DURATION} onClose={handleToastClose}>
          {saved ?
            <MuiAlert elevation={6} variant="filled" onClose={handleToastClose} severity="success">
              Guardado con éxito!
            </MuiAlert>
            :
            <MuiAlert elevation={6} variant="filled" onClose={handleToastClose} severity="error">
              Ha ocurrido un error!
            </MuiAlert>
          }
        </Snackbar>
      </GestionContext.Provider >
    </SiniestroContext.Provider >
  )
}
