import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { BottomRightButton, DangerButton } from "../components/utils/Buttons";
import useUsersConnection from "../api/UsersConnection"; // Adjust the import path as necessary
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Snackbar,
  TextField,
  Tooltip,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { FormControl } from "@material-ui/core";

const ALERT_DURATION = 4000; // ms

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const UsersConnection = useUsersConnection();
  // State for controlling the visibility of the modal and form inputs
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState({
    user_id: "",
    given_name: "",
    family_name: "",
    email: "",
    password: "",
    roles: [],
    app_metadata: {},
    blocked: false,
  });
  // to keep track of which row is selected and retrieve original user
  const [userIndex, setUserIndex] = useState(-1);
  const [isNewUser, setIsNewUser] = useState(!!selectedUser.user_id);
  // Toast
  const [toast, setToast] = useState(false);
  // Status state for the toast
  const [toastSeverity, setToastSeverity] = useState("success"); // "info" | "success" | "warning" | "error
  const [toastMessage, setToastMessage] = useState("");
  const [requiredField, setRequiredField] = useState(false);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await UsersConnection.get();
      console.log("users:", response.data);
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users", error.response);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await UsersConnection.get("/roles");
      console.log("roles:", response.data);
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching roles", error.response);
    }
  };

  useEffect(() => {
    console.log("useEffect fetching all");
    setIsLoading(true);
    fetchRoles();
    fetchUsers();
  }, []);

  useEffect(() => {
    console.log("selectedUser", selectedUser);
    setRequiredField(!selectedUser.user_id);
  }, [selectedUser]);

  const columns = [
    {
      name: "nombre",
      label: "Nombre",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>{`${users[dataIndex].given_name} ${users[dataIndex].family_name}`}</div>
          );
        },
      },
    },
    {
      name: "email",
      label: "Email",
    },
    {
      name: "rol",
      label: "Rol",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const roles = users[dataIndex].roles;
          if (roles && roles.length > 0) {
            const transformedRoles = roles.map((r) => r.name).join(", ");
            return <div>{transformedRoles}</div>;
          } else {
            return <div>No tiene rol asignado</div>; // default
          }
        },
      },
    },
    {
      name: "last_login",
      label: "Último login",
      options: {
        customBodyRenderLite: (dataIndex) => {
          if (users[dataIndex].last_login === undefined) {
            return <div>No ha iniciado sesión</div>;
          }
          const lastLogin = new Date(users[dataIndex].last_login);
          return <div>{lastLogin.toLocaleString()}</div>;
        },
      },
    },
  ];

  // Define options for MUIDataTable
  const options = {
    filterType: "checkbox",
    textLabels: {
      body: {
        noMatch: isLoading ? <CircularProgress /> : "No hay data.",
      },
    },
    onRowClick: (rowData, rowMeta) => handleRowClick(rowData, rowMeta),
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToast(false);
  };

  const handleRowClick = (rowData, rowMeta) => {
    let user = users[rowMeta.dataIndex];
    // to keep track of the selected user before edits
    setUserIndex(rowMeta.dataIndex);
    // Check if user fields are undefined, if so, initialize them with default values to avoid React uncontrolled components
    user = {
      user_id: user.user_id || "",
      given_name: user.given_name || "",
      family_name: user.family_name || "",
      email: user.email || "",
      password: user.password || "",
      roles: user.roles || [],
      app_metadata: user.app_metadata || {},
      blocked: user.blocked || false,
    };
    setSelectedUser(user);
    setOpenModal(true);
  };

  const saveUser = async (user) => {
    try {
      if (isNewUser) {
        console.log("Creating user:", user);
        await UsersConnection.post("/", user);
        setToastMessage("Usuario agregado correctamente");
      } else {
        console.log("Updating user:", user);
        // remove the email if it is not changed to avoid a0 error: cannot update email and psw simultaneously
        if (selectedUser.email === user.email) delete user.email;
        await UsersConnection.put(`/${user.user_id}`, user);
        setToastMessage("Usuario actualizado correctamente");
      }
      // Update the selectedUser state
      setSelectedUser(user);
      // Refetch users to update the list
      // fetchUsers();
      setToastSeverity("success");
    } catch (error) {
      if (error.response.status === 409) {
        console.log(
          "Error: conflicto, el PAS esta asociado a siniestro.",
          error.response
        );
        setToastMessage(
          "Usuario guardado correctamente pero el rol no pudo modificarse, probablemente esté asociado a un siniestro."
        );
        setToastSeverity("warning");
      } else {
        console.error("Error saving user:", error.response);
        setToastMessage("Error guardando usuario");
        setToastSeverity("error");
        setOpenModal(false);
      }
    }
    setOpenModal(false);
    setToast(true);
    fetchUsers();
  };

  const deleteUser = async (userId) => {
    try {
      await UsersConnection.delete(`/${userId}`);
      setToastMessage("Usuario eliminado correctamente");
      setToastSeverity("success");
    } catch (error) {
      if (error.response.status === 409) {
        console.log(
          "Error eliminando: conflicto, el PAS esta asociado a siniestro.",
          error.response
        );
        setToastMessage(
          "Usuario bloqueado. No se eliminó ya que está asociado a un siniestro."
        );
        setToastSeverity("warning");
      } else {
        console.error("Error deleting user:", error.response);
        setToastMessage("Error eliminando usuario");
        setToastSeverity("error");
      }
    }
    setOpenModal(false);
    setToast(true);
    fetchUsers();
  };

  // MAIN RENDER
  return (
    <div>
      <MUIDataTable
        title={"Usuarios"}
        data={users}
        columns={columns}
        options={options}
      />
      <BottomRightButton
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
        onClick={() => {
          setSelectedUser({
            given_name: "",
            family_name: "",
            email: "",
            password: "",
            roles: [],
          });
          setIsNewUser(true);
          setOpenModal(true);
        }}
      >
        Nuevo Usuario
      </BottomRightButton>
      <EditUsers
        selectedUser={selectedUser}
        openModal={openModal}
        setOpenModal={setOpenModal}
        roles={roles}
        deleteUser={deleteUser}
        saveUser={saveUser}
        openConfirmDialog={openConfirmDialog}
        setOpenConfirmDialog={setOpenConfirmDialog}
        requiredField={requiredField}
      />
      <Snackbar
        open={toast}
        autoHideDuration={ALERT_DURATION}
        onClose={handleToastClose}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleToastClose}
          severity={toastSeverity}
        >
          {toastMessage}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

// Modal component for creating/editing users
const EditUsers = ({
  selectedUser,
  openModal,
  setOpenModal,
  roles,
  deleteUser,
  saveUser,
  openConfirmDialog,
  setOpenConfirmDialog,
  requiredField,
}) => {
  const [localUser, setLocalUser] = useState(selectedUser);
  const [isEditingUser, setIsEditingUser] = useState(true); // false if creating

  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = () => {
    const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    setIsEmailValid(emailRegex.test(localUser.email));
    setIsPasswordValid(localUser.password.length >= 6);
    setIsFormValid(isEmailValid && isPasswordValid && !isEditingUser);
  };

  useEffect(() => {
    setLocalUser(selectedUser);
    setIsEditingUser(!!selectedUser.user_id);
    console.log("localUser", selectedUser);
  }, [selectedUser]);

  useEffect(() => {
    if (isEditingUser) setIsFormValid(true); // manual ovrride
    else validateForm();
  });

  const handleChange = (event) => {
    event.preventDefault();
    setLocalUser({
      ...localUser,
      [event.target.name]: event.target.value || "",
    });
  };

  const handleRoleChange = (event, role) => {
    event.persist();
    setLocalUser({
      ...localUser,
      roles: event.target.checked
        ? [...localUser.roles, role]
        : localUser.roles.filter((r) => r.id !== role.id),
    });
  };

  const handleBlockChange = (event) => {
    event.persist();
    setLocalUser({
      ...localUser,
      blocked: event.target.checked,
    });
  };

  // EDIT USERS MODAL
  return (
    <>
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>
          {isEditingUser ? "Editar Usuario" : "Nuevo Usuario"}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="given_name"
            name="given_name"
            label="Given Name"
            type="text"
            fullWidth
            value={localUser.given_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="family_name"
            name="family_name"
            label="Apellido"
            type="text"
            fullWidth
            value={localUser.family_name}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="Email"
            type="email"
            required={requiredField}
            error={!isEmailValid && !isEditingUser}
            fullWidth
            value={localUser.email}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            required={requiredField}
            error={!isPasswordValid && !isEditingUser}
            fullWidth
            value={localUser.password}
            onChange={handleChange}
          />
          <FormGroup style={{ display: "grid", gridAutoFlow: "column" }}>
            {roles.map((role) => (
              <FormControl style={{ width: "120px" }} key={role.id}>
                <FormControlLabel
                  component="legend"
                  label={role.name}
                  control={
                    <Checkbox
                      checked={!!localUser.roles?.find((r) => r.id === role.id)}
                      onChange={(event) => handleRoleChange(event, role)}
                      name="roles"
                      value={role.id}
                    />
                  }
                />
              </FormControl>
            ))}
            <FormControl style={{ justifySelf: "flex-end" }}>
              <FormControlLabel
                component="legend"
                label="Bloqueado"
                control={
                  <Checkbox
                    color="default"
                    checked={!!localUser.blocked}
                    onChange={(event) => handleBlockChange(event)}
                    name="blocked"
                    value={localUser.blocked}
                  />
                }
              />
            </FormControl>
          </FormGroup>
        </DialogContent>
        <DialogActions style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }}>
            <Tooltip
              title={
                localUser?.roles?.find((r) => r.name === "Admin")
                  ? "No se puede eliminar usuarios de rol Admin"
                  : ""
              }
            >
              <span>
                {isEditingUser && (
                  <DangerButton
                    variant="contained"
                    style={{ marginLeft: 0 }}
                    onClick={() => setOpenConfirmDialog(true)}
                    disabled={
                      !!localUser?.roles?.find((r) => r.name === "Admin")
                    }
                  >
                    Eliminar
                  </DangerButton>
                )}
              </span>
            </Tooltip>
          </div>
          <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
          <Button
            onClick={() => saveUser(localUser)}
            variant="contained"
            color="primary"
            disabled={!isFormValid}
          >
            {isEditingUser ? "Guardar" : "Crear"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* CONFIRMAR DELETE USER */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro/a de la eliminación de este usuario?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancelar</Button>
          <DangerButton
            onClick={() => {
              deleteUser(localUser.user_id);
              setOpenConfirmDialog(false);
            }}
          >
            Eliminar
          </DangerButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UsersList;
