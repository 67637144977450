import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef } from "react";

export default function useUsersConnection() {
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();

  const api = useRef(
    axios.create({
      baseURL: `${process.env.REACT_APP_BACKEND}/api/v2/usuarios`,
    })
  );

  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptorId = currentAPI.interceptors.request.use(
      async (config) => {
        const token = await getAccessTokenSilently();
        config.headers.Authorization = `Bearer ${token}`;
        config.cancelToken = axios.CancelToken.source().token;
        return config;
      }
    );
    const responseInterceptorId = currentAPI.interceptors.response.use(
      null,
      async (err) => {
        if (err.config && err.response && err.response.status === 401) {
          await loginWithRedirect({
            redirect_uri: window.location.origin,
          });
        }
        return Promise.reject(err);
      }
    );

    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
      currentAPI.interceptors.response.eject(responseInterceptorId);
    };
  });
  return api.current;
}
