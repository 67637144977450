import React from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom"

import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './theme'
// import logo from './logo.svg';
import './App.css';

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DayjsUtils from '@date-io/dayjs'

import Home from './routes/Home'
import Header from './components/Header'
import SiniestrosLista from './routes/SiniestrosLista'
import Siniestro from "./routes/Siniestro"
import { SiniestrosContextProvider } from "./context/SiniestrosContext"
import Auth0ProviderWithHistory from "./context/auth0Provider"
import PrivateRoute from "./components/PrivateRoute"
import UsersList from "./routes/Users"
import AseguradorasAdmin from "./routes/Aseguradoras"


function App() {
  return (
    <Auth0ProviderWithHistory>
      <ThemeProvider theme={theme}>
        <SiniestrosContextProvider>
          <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Router>
              <Header />
              <Container maxWidth="xl">
                <Box my={theme.spacing(0.5)}>
                  <Switch>
                    <PrivateRoute path="/siniestros/:id" component={Siniestro} />
                    <PrivateRoute path="/siniestros" component={SiniestrosLista} />
                    <PrivateRoute path="/usuarios" component={UsersList} />
                    <PrivateRoute path="/aseguradoras" component={AseguradorasAdmin} />
                    <Route path="/" component={Home} />
                  </Switch>
                </Box>
              </Container >
            </Router >
          </MuiPickersUtilsProvider>
        </SiniestrosContextProvider>
      </ThemeProvider>
    </Auth0ProviderWithHistory>
  );
}

export default App;
