import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import useAseguradrasConnection from "../api/AseguradorasConnection";
import { BottomRightButton, DangerButton } from "../components/utils/Buttons";
import useCheckAdmin from "../api/CheckAdmin";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import theme from "../theme";

const ALERT_DURATION = 4000; // ms

const AseguradorasAdmin = () => {
  const AseguradorasConnection = useAseguradrasConnection();
  const [aseguradoras, setAseguradoras] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editIndex, setEditIndex] = useState(-1); // New state to track edit mode
  const [editValue, setEditValue] = useState(""); // New state to track the edited value
  const esAdmin = useCheckAdmin();
  const [openModal, setOpenModal] = useState(false);
  const [newAseguradoraName, setNewAseguradoraName] = useState("");
  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastSeverity, setToastSeverity] = useState("success");
  const [toastMessage, setToastMessage] = useState("");
  // confirmation dialog
  const [isDeleting, setIsDeleting] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const openSuccessToast = (message) => {
    setToastMessage(message);
    setToastSeverity("success");
    setOpenToast(true);
  };

  const openErrorToast = (message) => {
    setToastMessage(message);
    setToastSeverity("error");
    setOpenToast(true);
  };

  const handleDeleteClick = (index) => {
    setIsDeleting(true);
    setEditIndex(index);
    setEditValue(aseguradoras[index].esa_razonsocial);
    setOpenConfirmDialog(true);
  };

  useEffect(() => {
    fetchAseguradoras();
  }, []);

  const fetchAseguradoras = async () => {
    const response = await AseguradorasConnection.get();
    const data = await response.data;
    setAseguradoras(data);
    setIsLoading(false);
  };

  const saveAseguradora = async (index) => {
    if (index === -1) {
      console.log("save new aseguradora with value:", newAseguradoraName);
      try {
        await AseguradorasConnection.post("/", {
          esa_razonsocial: newAseguradoraName,
        });
        openSuccessToast(
          `Aseguradora creada correctamente: ${newAseguradoraName}`
        );
      } catch (error) {
        console.error("Error while creating new aseguradora:", error);
        openErrorToast(
          `Error creando Aseguradora: ${error.response.data.message}`
        );
      }
      setNewAseguradoraName(""); // Reset the input field after saving
    } else {
      // Save the edited aseguradora
      const aseguradoraId = aseguradoras[index].pna_id;
      console.log("save aseguradoraId", aseguradoraId, "with value", editValue);
      try {
        await AseguradorasConnection.put("/" + parseInt(aseguradoraId), {
          esa_razonsocial: editValue,
        });
        openSuccessToast(`Aseguradora actualizada correctamente: ${editValue}`);
      } catch (error) {
        console.error("Error while updating aseguradora:", error);
        openErrorToast(
          `Error actualizando Aseguradora: ${error.response.data.message}`
        );
      }
      setEditIndex(-1); // Exit edit mode
    }
    fetchAseguradoras();
  };

  const deleteAseguradora = async (aseguradoraId) => {
    // get name for toast
    const name = aseguradoras.find(
      (a) => a.pna_id === parseInt(aseguradoraId)
    ).esa_razonsocial;
    try {
      await AseguradorasConnection.delete("/" + parseInt(aseguradoraId));
      openSuccessToast(`Aseguradora eliminada correctamente: ${name}`);
    } catch (error) {
      console.error("Error deleting aseguradora:", error);
      if (error.response.status === 422) {
        openErrorToast(
          `No se puede eliminar la aseguradora "${name}" porque tiene siniestros asociados`
        );
      } else {
        openErrorToast(
          `Error al eliminar aseguradora: "${name}" // ${error.response.data.message}`
        );
      }
    }
    fetchAseguradoras();
  };

  const columns = [
    {
      name: "esa_razonsocial",
      label: "Nombre",
      options: {
        setCellHeaderProps: () => ({ style: { minWidth: "500px" } }),
        customBodyRenderLite: (dataIndex) => {
          return editIndex === dataIndex && !isDeleting ? (
            <TextField
              value={editValue}
              onChange={(e) => setEditValue(e.target.value)}
              fullWidth
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  // Save on Enter
                  saveAseguradora(dataIndex);
                } else if (e.key === "Escape") {
                  // Cancel on Esc
                  setEditIndex(-1);
                  setEditValue("");
                }
              }}
            />
          ) : (
            aseguradoras[dataIndex].esa_razonsocial
          );
        },
      },
    },
    {
      name: "Editar",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return esAdmin ? (
            editIndex === dataIndex && !isDeleting ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ marginRight: theme.spacing(1) }}
                  onClick={() => saveAseguradora(dataIndex)}
                >
                  Guardar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    // Cancel edit
                    setEditIndex(-1);
                    setEditValue("");
                  }}
                >
                  Cancelar
                </Button>
              </>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setIsDeleting(false);
                  setEditIndex(dataIndex);
                  setEditValue(aseguradoras[dataIndex].esa_razonsocial);
                }}
              >
                Editar
              </Button>
            )
          ) : (
            "N/A"
          );
        },
      },
    },
    {
      name: "Eliminar",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return esAdmin ? (
            <DangerButton
              variant="contained"
              onClick={() => handleDeleteClick(dataIndex)}
            >
              Eliminar
            </DangerButton>
          ) : (
            "N/A"
          );
        },
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    onRowsDelete: (rowsDeleted) => {
      const idsToDelete = rowsDeleted.data.map(
        (d) => aseguradoras[d.dataIndex].pna_id
      );
      idsToDelete.forEach((id) => deleteAseguradora(id));
      return false; // Prevent the default deletion behavior
    },
  };

  const data = aseguradoras.map((aseguradora) => [aseguradora.esa_razonsocial]);

  return (
    <>
      <MUIDataTable
        title={"Aseguradoras"}
        data={data}
        columns={columns}
        options={options}
      />
      {esAdmin && (
        <BottomRightButton
          variant="contained"
          color="primary"
          onClick={() => {
            setEditIndex(-1); // Reset edit index to allow for a new aseguradora to be added
            setNewAseguradoraName("");
            setOpenModal(true);
          }}
          disabled={isLoading}
        >
          Nueva
        </BottomRightButton>
      )}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Nueva Aseguradora</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Nombre de Aseguradora"
            type="text"
            fullWidth
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                saveAseguradora(-1);
                setOpenModal(false);
              }
            }}
            value={newAseguradoraName}
            onChange={(e) => setNewAseguradoraName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="primary">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              saveAseguradora(-1);
              setOpenModal(false);
            }}
            color="primary"
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle>Confirmar eliminación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Está seguro/a de la eliminación de esta aseguradora?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDialog(false)}>Cancelar</Button>
          <DangerButton
            variant="contained"
            onClick={() => {
              deleteAseguradora(aseguradoras[editIndex].pna_id);
              setOpenConfirmDialog(false);
            }}
          >
            Eliminar
          </DangerButton>
        </DialogActions>
      </Dialog>

      {/* Toast Messages */}
      <Snackbar
        open={openToast}
        autoHideDuration={ALERT_DURATION}
        onClose={() => setOpenToast(false)}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setOpenToast(false)}
          severity={toastSeverity}
        >
          {toastMessage}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AseguradorasAdmin;
