import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

const NAMESPACE = `https://${process.env.REACT_APP_AUTH0_AUDIENCE}/roles`; // auth0 role namespace

export default function useCheckAbogado() {
  const { user, isAuthenticated } = useAuth0();

  return React.useMemo(() => {
    let result = false;

    if (isAuthenticated && user && user[NAMESPACE]) {
      result = user[NAMESPACE].includes("Abogado");
    }
    console.log("esAbogado hook?", result);
    return result;
  }, [user, isAuthenticated]);
}
